import * as React from 'react';

import { a } from 'react-spring';

import { useIntersect } from 'hooks/useIntersect';
import { useAnimateInTrail, IAnimateInProps } from 'hooks/useAnimateInTrail';
import { initialOffset } from 'components/Animations/SpringProperties/SpringProperties';

import { IHomeService } from './HomeService';

import { ButtonLink } from 'components/Links/Index';


interface IOwnSpecificProps {
  service: IHomeService;
}

type IOwnProps = IOwnSpecificProps & IAnimateInProps;

type IProps = IOwnProps;

const HomeServiceMessageComponent: React.FC<IProps> = ({ animationReady, service, ...props }) => {
  const [inViewRef, inView] = useIntersect({
    startObserving: animationReady,
  });

  const animateInTrail = useAnimateInTrail(inView, 5);

  const logoStyles = {
    opacity: animateInTrail[0].active,
    transform: animateInTrail[0].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const titleStyles = {
    opacity: animateInTrail[1].active,
    transform: animateInTrail[1].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const separatorStyles = {
    opacity: animateInTrail[2].active,
    transform: animateInTrail[2].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const lineStyles = {
    width: animateInTrail[2].active.to(x => `calc(${x} * 100%)`),
  };

  const descriptionStyles = {
    opacity: animateInTrail[3].active,
    transform: animateInTrail[3].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const ctaStyles = {
    opacity: animateInTrail[4].active,
    transform: animateInTrail[4].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  return (
    <div ref={inViewRef} className='messageWrapper'>
      <a.img
        src={'/assets/images/work/' + service.projectLogoUrl}
        alt={service.projectName}
        style={logoStyles}
      />
      <a.h1 style={titleStyles}>
        {service.title}
      </a.h1>
      <a.div className='separator' style={separatorStyles}>
        <a.div className='line' style={lineStyles} />
      </a.div>
      <a.p style={descriptionStyles}>
        {service.description}
      </a.p>
      <a.div className='ctaWrapper' style={ctaStyles}>
        {(service.caseStudy ?
          <ButtonLink
            to={service.linkUrl}
            type='small'
            arrow
            animationReady={animationReady}
            animateIn={animateInTrail[4].active.to(x => x > 0 ? 1 : 0)}
            title='Check out the case study'
          >
            Check out the case study
          </ButtonLink>
          :
          <div className='comingSoon'>Case study coming soon</div>
        )}
      </a.div>
    </div>
  );
};

export const HomeServiceMessage = HomeServiceMessageComponent;
