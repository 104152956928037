import * as React from 'react';

import { a } from 'react-spring';

import { useIntersect } from 'hooks/useIntersect';
import { useAnimateInTrail, IAnimateInProps } from 'hooks/useAnimateInTrail';
import { initialOffset } from 'components/Animations/SpringProperties/SpringProperties';
import { InterfaceWrapper } from 'components/Layout/InterfaceWrapper/InterfaceWrapper';

import { ButtonLink } from 'components/Links/Index';

type IOwnProps = IAnimateInProps;

export type IProps = IOwnProps;

const StudioBlurb: React.FC<IProps> = ({ animationReady, ...props }) => {
  const [inViewRef, inView] = useIntersect({
    startObserving: animationReady,
    rootMargin: "-300px 0px -450px 0px",
  });

  const animateInTrail = useAnimateInTrail(inView, 2);

  const titleStyles = {
    opacity: animateInTrail[0].active,
    transform: animateInTrail[0].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const ctaStyles = {
    opacity: animateInTrail[1].active,
    transform: animateInTrail[1].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  return (
    <InterfaceWrapper animationReady={animationReady}>
      <section ref={inViewRef} className='fullStudioWrapper'>
        <div className='messageWrapper'>
          <a.h1 style={titleStyles}>
            Qore is a full-service digital studio with a small but talented team that create delightful<br />digital products, brands and experiences. With a unique approach guided by thoughtful design,<br />we deliver identities and systems that thrill users and generate business results.
          </a.h1>
          <a.div className='ctaWrapper' style={ctaStyles}>
            <ButtonLink
              to='/services'
              type='small'
              arrow
              animationReady={animationReady}
              animateIn={animateInTrail[1].active.to(x => x > 0 ? 1 : 0)}
              title='Our services'
            >
              Our services
            </ButtonLink>
          </a.div>
        </div>
      </section>
    </InterfaceWrapper>
  );
};

export default StudioBlurb;
