import * as React from 'react';

import { InsideParallaxImage } from "components/Image/Index";

import { a } from 'react-spring';

import { useIntersect } from 'hooks/useIntersect';
import { useAnimateInSpring, IAnimateInProps } from 'hooks/useAnimateInSpring';

import { Parallax } from 'react-scroll-parallax';

import classNames from 'classnames';

import { HomeServiceMessage } from './HomeServiceMessage';

interface IImage {
  url: string;
  alt: string;
}

export interface IHomeService {
  title: string;
  description: string;
  linkUrl: string;
  caseStudy: boolean;
  projectLogoUrl: string;
  projectName: string;
  projectImage: IImage;
  projectImagePosition: number;
}

export interface IHomeServices {
  [name: string]: IHomeService;
}

interface IOwnSpecificProps {
  contentAlignRight: boolean;
  firstOne: boolean;
  service: IHomeService;
}

type IOwnProps = IOwnSpecificProps & IAnimateInProps;

type IProps = IOwnProps;

const HomeServiceComponent: React.FC<IProps> = ({ animationReady, service, contentAlignRight, firstOne, ...props }) => {
  const [inViewRef, inView] = useIntersect({
    startObserving: animationReady,
    rootMargin: "-150px 0px -150px 0px",
  });

  const animateInSpring = useAnimateInSpring(inView);

  const imageContainerStyles = {
    opacity: animateInSpring.active,
    transform: animateInSpring.active.to(x => `translateX(calc((${x} * (-1 * ${imageTransform}px)) + ${imageTransform}px + ${service.projectImagePosition}px))`),
  };

  const imageTransform = (contentAlignRight ? -1 : 1) * 50;
  return (
    <section
      ref={inViewRef}
      className={classNames(
        'fullServiceWrapper',
        { contentRight: contentAlignRight },
        { contentLeft: !contentAlignRight },
        { firstOne },
      )}
    >
      <HomeServiceMessage service={service} animationReady={animationReady} />
      <a.div className='associatedImage' style={imageContainerStyles}>
        <Parallax
          y={['-100px', '100px']}
          tagOuter="figure"
        >
          <div className='parallaxInner'>
            <InsideParallaxImage
              width='100%'
              height='100%'
              src={'/assets/images/work/' + service.projectImage.url}
              alt={service.projectImage.alt}
              fit='scaleDown'
              placeholderBackgroundColor='transparent'
              animateImageIn={1}
              animationType={'fadeIn'}
            />
          </div>
        </Parallax>
      </a.div>
    </section>
  );
};

export const HomeService = HomeServiceComponent;
