import * as React from 'react';

import classNames from 'classnames';

interface IOwnSpecificProps {
  padding?: string;
}

type IOwnProps = IOwnSpecificProps;

type IProps = IOwnProps;

const FullSectionContentComponent: React.FC<IProps> = ({ padding, ...props }) => {
  return (
    <div
      className={classNames(
        'contentWrapper',
        { ['padding-' + padding]: (padding != null) },
      )}
    >
      {props.children}
    </div>
  );
};

export const FullSectionContent = FullSectionContentComponent;
